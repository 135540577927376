<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :show-add-button="false"
        :descending="true"
        title="Failed Queued Logs"
        icon="mdi-comment-alert"
        stateendpoint="failedQueuedLogs.failedQueuedLogs"
        :actions="actions"
        :show-default-action-buttons="false"
        :show-clear-button="true"
        clear-button-text="Clear Failed Queued Logs"
    />
  </div>
</template>
<script>
import ListPage from "../../components/ListPage";

export default {
  name: 'FailedQueuedLogs',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Failed Queued Logs | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage},
  data() {
    return {
      headers: [
        {
          text: 'Queue Name',
          value: 'queue'
        },
        {
          text: 'Total Queued',
          align: 'right',
          value: 'totalQueued'
        }
      ],
      columns: [
        {value: 'logs'},
        {value: 'action'}
      ],
      actions: {
        load: 'loadFailedQueuedLogs',
        clearData: 'clearFailedQueuedLogs'
      }
    }
  }
}
</script>
